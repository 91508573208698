import React, { PropsWithChildren, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Layout } from '@/components/layout';
import { Auth0UrqlProvider } from '@/components/urql';
import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react';

const PrivateRouter = React.lazy(() => import('./private'));
const HomePage = React.lazy(() => import('@/pages/home'));
const SignupPage = React.lazy(() => import('@/pages/auth/signup'));
const SignupMailSentPage = React.lazy(() => import('@/pages/auth/signup/mail-sent'));
const VerifyPage = React.lazy(() => import('@/pages/auth/verify'));
const VerifyCompletePage = React.lazy(() => import('@/pages/auth/verify/complete'));
const JoinCompanyPage = React.lazy(() => import('@/pages/join-company'));
const ContainerECPage = React.lazy(() => import('@/pages/containerec'));
const MyBookingPage = React.lazy(() => import('@/pages/mybooking'));
const MyTicketPage = React.lazy(() => import('@/pages/myticket'));
const NotFound = React.lazy(() => import('@/pages/notfound'));
const MaintenancePage = React.lazy(() => import('@/pages/maintenance'));
const GroupOrderPage = React.lazy(() => import('@/pages/grouporder'));
const FavoritesPage = React.lazy(() => import('@/pages/favorites'));
const BlogListPage = React.lazy(() => import('@/pages/blog'));
const BlogDetailPage = React.lazy(() => import('@/pages/blog/[id]'));
const InquiryPage = React.lazy(() => import('@/pages/inquiry'));
const InquiryCompletePage = React.lazy(() => import('@/pages/inquiry/complete'));

const domain = import.meta.env.VITE_AUTH0_DOMAIN as string;
const client = import.meta.env.VITE_AUTH0_CLIENT_ID as string;

const audience = import.meta.env.VITE_AUTH0_AUDIENCE as string;

const AuthProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    // If using a Hash Router, you need to use window.history.replaceState to
    // remove the `code` and `state` query parameters from the callback url.
    // window.history.replaceState({}, document.title, window.location.pathname);
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={client}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      // cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <p>Loading...</p>
    </div>
  );
};
const PasswordChangeRequest = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        view: 'change-password',
        prompt: 'login',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <p>Loading...</p>
    </div>
  );
};

export const Router = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Auth0UrqlProvider>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/containers" element={<ContainerECPage />} />
              <Route path="/home" element={<Navigate replace to="/" />} />
              <Route element={<PrivateRouter />} />
              <Route path="/mybooking/:displayId?" element={<MyBookingPage />} />
              <Route path="/myticket" element={<MyTicketPage />} />
              {/* 過去に login で提供していたページを新しいログインページにリダイレクトさせる */}
              <Route path="/login" element={<Navigate replace to="/auth/login" />} />
              <Route path="/auth/login" element={<Login />} />
              <Route path="/auth/password-reset-request" element={<PasswordChangeRequest />} />
              <Route path="/auth/sign-up" element={<SignupPage />} />
              <Route path="/auth/sign-up/mail-sent" element={<SignupMailSentPage />} />
              <Route path="/auth/verify" element={<VerifyPage />} />
              <Route path="/auth/verify/complete" element={<VerifyCompletePage />} />
              <Route path="/company/join" element={<JoinCompanyPage />} />
              <Route path="/grouporder" element={<GroupOrderPage />} />
              <Route path="/favorites" element={<FavoritesPage />} />
              <Route path="/blog" element={<BlogListPage />} />
              <Route path="/blog/category/:name" element={<BlogListPage />} />
              <Route path="/blog/:id" element={<BlogDetailPage />} />
              <Route path="/inquiry" element={<InquiryPage />} />
              <Route path="/inquiry/complete" element={<InquiryCompletePage />} />
            </Route>
            {/* duplicated  */}

            <Route path="maintenance" element={<MaintenancePage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Auth0UrqlProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};
