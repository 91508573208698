/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from 'urql';
import { graphql } from '@/gql';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Box, Button, Modal, useMediaQuery } from '@mui/material';

const Header_Query = graphql(/* GraphQL */ `
  query Header_Query {
    userViewer {
      id
      firstName
      lastName
      email
      company {
        id
        name
      }
    }
  }
`);

export const Header = React.memo(() => {
  const { user, logout, loginWithRedirect } = useAuth0();
  const location = useLocation();

  const [result] = useQuery({
    query: Header_Query,
    pause: !user,
  });

  const { data } = result;

  if (!data && user) {
    //auth0上にのみデータがある場合
    throw new Error('user exists, but data is undefined');
  }

  if (user) {
    (window as any).dataLayer.push({
      userId: user.sub,
    });
  }

  const handleLogout = React.useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);
  const handleLogin = React.useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    const returnPath = `${location.pathname}?${queryParams.toString()}`;

    loginWithRedirect({
      appState: { returnTo: returnPath },
    });
  }, [loginWithRedirect, location.pathname, location.search]);

  const domain = import.meta.env.VITE_AUTH0_DOMAIN as string;
  const STAGINGURI = 'stg-giho-willbox.jp.auth0.com' as string;
  const STAGING = 'Staging' as string;
  const displayStaging = domain === STAGINGURI ? STAGING : '';

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [menuOpen, setMenuOpen] = React.useState(false);
  const isDesktop = useMediaQuery('(min-width:961px)');

  return (
    <>
      <div className="bg-shade-white-default z-header pc:h-20 sticky top-0 flex h-14 w-full min-w-full flex-row items-center justify-between border-b-[1px] border-[#D2D2D2] p-5">
        {/* left */}
        <Link to="/" className="shrink-0" onClick={() => setMenuOpen(false)}>
          <div className="flex items-center gap-3.5">
            <img src="/images/logo/logo_company_name.svg" width="185" height="40" alt="CONTAINER EC" />
            <p className="text-lg font-bold ">{displayStaging}</p>
          </div>
        </Link>
        {/* right */}
        <div className="flex items-center">
          {isDesktop && (
            <>
              <Link to="/containers">
                <Button className="pc:text-base pc:m-2 pc:px-3 m-1 h-[2rem] rounded-[24px] px-0 font-sans text-sm text-[#000000] hover:bg-[#E0F3ED]">
                  検索
                </Button>
              </Link>
              <Link to="/favorites">
                <Button className="pc:text-base pc:m-2 pc:px-3 m-1 h-[2rem] rounded-[24px] px-0 font-sans text-sm text-[#000000] hover:bg-[#E0F3ED]">
                  お気に入り
                </Button>
              </Link>
              <Link to="/blog">
                <Button className="pc:text-base pc:m-2 pc:px-3 m-1 h-[2rem] rounded-[24px] px-0 font-sans text-sm text-[#000000] hover:bg-[#E0F3ED]">
                  ブログ
                </Button>
              </Link>
              {/* 共同購買再開時に導線を復活させる */}
              {/* <Link to="/myticket">
                <Button className="pc:text-base pc:m-2 pc:px-3 m-1 h-[2rem] rounded-[24px] px-0 font-sans text-sm text-[#000000] hover:bg-[#E0F3ED]">
                  マイチケット
                </Button>
              </Link> */}
              <Link to="/mybooking">
                <Button className="pc:text-base pc:m-2 pc:px-3 m-1 h-[2rem] rounded-[24px] px-0 font-sans text-sm text-[#000000] hover:bg-[#E0F3ED]">
                  マイブッキング
                </Button>
              </Link>
            </>
          )}
          {data ? (
            <>
              <Avatar
                onClick={handleOpen}
                className="ml-4"
                sx={{
                  width: 32,
                  height: 32,
                  backgroundColor: '#0DB189',
                  transition: 'background-color 0.2s ease',
                  '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: '#0FCC9E',
                  },
                }}
              >
                {data.userViewer?.email?.charAt(0)}
              </Avatar>
              <Modal
                open={open}
                onClose={handleClose}
                sx={{ '& .MuiBackdrop-root': { backgroundColor: 'transparent' } }}
              >
                <Box
                  className="flex w-[20rem] flex-col rounded-[14px] bg-[#FFFFFF] drop-shadow-2xl"
                  style={{ position: 'absolute', top: '4rem', right: '2.2rem' }}
                >
                  <div className="flex justify-end">
                    <Box
                      onClick={handleClose}
                      className="absolute right-2.5 top-2 h-9 w-9 rounded-full "
                      sx={{
                        backgroundColor: '#FFFFFF',
                        transition: 'background-color 0.2s ease',
                        '&:hover': {
                          cursor: 'pointer',
                          backgroundColor: '#E0F3ED',
                        },
                      }}
                    >
                      <div className="relative left-3 top-3">
                        <img src="/img/close_icon.svg" width="12" height="12" alt="close icon" />
                      </div>
                    </Box>
                  </div>

                  <div className="pb-6 text-center">
                    <div className="mb-3 mt-6 text-[13px] leading-none">{`${data.userViewer.company?.name ?? ''}`}</div>
                    <div className="mb-3 text-[18px] font-bold leading-none">{`${data.userViewer.lastName} ${data.userViewer.firstName}`}</div>
                    <div className="text-[13px] leading-none"> {data.userViewer.email}</div>
                  </div>
                  <div>
                    <div className="flex justify-evenly">
                      <Button
                        className="mx-2 w-auto rounded-[24px] bg-[#EFF2F1] text-center font-sans text-[#000000] hover:bg-[#E0F3ED]"
                        onClick={handleLogout}
                      >
                        <img src="/img/logout.svg" width="13" height="13" alt="logout icon" />
                        <span className="ml-2 text-sm font-bold">ログアウト</span>
                      </Button>

                      {/* 遷移先がないため一旦非表示 */}
                      {/* <Button className="mx-2 rounded-[24px] bg-[#EFF2F1] p-2 text-center text-[#000000] hover:bg-[#E0F3ED]">
                  <img src="/img/person.svg" width="10" height="10" alt="person icon" />
                  <span className="ml-2 text-sm font-bold">アカウントを管理</span>
                </Button> */}
                    </div>
                    <div className="m-6 flex justify-evenly text-sm">
                      <Link to="https://willbox.jp/privacy" target="_blank" rel="noreferrer">
                        <span className="text-[13px]">プライバシーポリシー</span>
                      </Link>
                      <span>|</span>
                      <Link
                        to="https://willbox.notion.site/Container-EC-0798ff86ded8487e8336c6a17ea5527f"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="m-1 text-[13px]">利用規約</span>
                      </Link>
                    </div>
                  </div>
                </Box>
              </Modal>
            </>
          ) : (
            <Button
              className="ml-2 w-auto rounded-[24px] bg-[#EFF2F1] px-[14px] py-[10px] text-center font-sans text-[#000000] hover:bg-[#E0F3ED]"
              onClick={handleLogin}
            >
              <span className="text-sm font-bold leading-3">ログイン</span>
            </Button>
          )}
          {!isDesktop && (
            <button
              className="ml-[6px] mr-[-10px] flex h-[44px] w-[44px] flex-col items-center justify-center gap-[3px]"
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
            >
              <span
                className={clsx('h-[2px] w-[18px] bg-[#000000] transition', menuOpen && 'translate-y-[5px] rotate-45')}
              />
              <span className={clsx('h-[2px] w-[18px] bg-[#000000] transition', menuOpen && 'opacity-0')} />
              <span
                className={clsx(
                  'h-[2px] w-[18px] bg-[#000000] transition',
                  menuOpen && 'translate-y-[-5px] -rotate-45',
                )}
              />
            </button>
          )}
        </div>
      </div>
      {!isDesktop && (
        <div
          className={clsx(
            'absolute bottom-0 right-0 top-14 z-10 overflow-auto bg-[#FFFFFF] transition-[left]',
            menuOpen ? 'left-0' : 'left-[100%]',
          )}
        >
          <ul>
            <li>
              <Link
                to="/"
                className="block px-6 py-4 text-[18px] hover:bg-[#E0F3ED]"
                onClick={() => setMenuOpen(false)}
              >
                ホーム
              </Link>
            </li>
            <li>
              <Link
                to="/containers"
                className="block px-6 py-4 text-[18px] hover:bg-[#E0F3ED]"
                onClick={() => setMenuOpen(false)}
              >
                検索
              </Link>
            </li>
            <li>
              <Link
                to="/favorites"
                className="block px-6 py-4 text-[18px] hover:bg-[#E0F3ED]"
                onClick={() => setMenuOpen(false)}
              >
                お気に入り
              </Link>
            </li>
            <li>
              <Link
                to="/myticket"
                className="block px-6 py-4 text-[18px] hover:bg-[#E0F3ED]"
                onClick={() => setMenuOpen(false)}
              >
                マイチケット
              </Link>
            </li>
            <li>
              <Link
                to="/mybooking"
                className="block px-6 py-4 text-[18px] hover:bg-[#E0F3ED]"
                onClick={() => setMenuOpen(false)}
              >
                マイブッキング
              </Link>
            </li>
          </ul>
        </div>
      )}
    </>
  );
});
