import { forwardRef, useCallback } from 'react';
import { CustomContentProps, SnackbarContent, SnackbarProvider, useSnackbar } from 'notistack';
import { IoCloseCircle } from 'react-icons/io5';
import { MdCheckCircle, MdClose } from 'react-icons/md';
import { RecoilRoot } from 'recoil';
import {
  Button,
  Card,
  CardActions,
  createTheme,
  IconButton,
  StyledEngineProvider,
  ThemeProvider,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { InternalServerError } from './components/error/InternalServerError';
import { Router } from './router';

try {
  const cleared = localStorage.getItem('cleared') === 'true';

  if (!cleared) {
    localStorage.clear();
    localStorage.setItem('cleared', 'true');
  }
} catch (e) {
  console.error(e);
}

declare module 'notistack' {
  interface VariantOverrides {
    successWithLink: {
      buttonName?: string;
      buttonHref?: string;
    };
    cancelProcess: {
      buttonName?: string;
      onButtonClick?: () => void;
    };
  }
}
export const App = () => {
  const rootElement = window.document.getElementById('root');
  const theme = createTheme({
    components: {
      MuiModal: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            width: '100%',
            borderRadius: 0,
          },
          containedPrimary: {
            boxShadow: 'none !important',
            background: '#0DB189 !important',
            color: '#FFFFFF',
            fontWeight: 'bold',
            '&:hover': {
              background: '#51cfb0 !important',
            },
            '&:disabled': {
              color: 'rgba(0, 0, 0, 0.26) !important',
              backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
            },
          },
          outlinedPrimary: {
            borderRadius: 0,
            borderWidth: 2,
            borderColor: '#0DB189',
            background: '#FFF',
            fontWeight: 'bold',
            '&:hover': {
              borderWidth: 2,
            },
          },
          sizeLarge: {
            fontSize: '1rem',
          },
        },
      },
      MuiPopover: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiPopper: {
        defaultProps: {
          container: rootElement,
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontWeight: '700',
            minWidth: 0,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: 4,
          },
        },
      },
    },
    palette: {
      primary: {
        main: '#0DB189',
      },
    },
  });

  return (
    <Sentry.ErrorBoundary fallback={InternalServerError} showDialog>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            Components={{
              successWithLink: SuccessWithLink,
              cancelProcess: CancelProcess,
              error: ErrorCustom,
            }}
          >
            <RecoilRoot>
              <Router />
            </RecoilRoot>
          </SnackbarProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Sentry.ErrorBoundary>
  );
};

interface SuccessWithLinkProps extends CustomContentProps {
  buttonName?: string;
  buttonHref?: string;
}

/**
 * Snackbarのカスタムコンテンツ
 * 表示：Successレイアウト
 * @param props notistackのCustomContentPropsをextend
 * @param buttonName ボタンのテキスト(任意)
 * @param buttonHref ボタンのリンク先(任意)
 */
export const SuccessWithLink = forwardRef<HTMLDivElement, SuccessWithLinkProps>(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent
      ref={ref}
      style={{ minWidth: "@media (min-width:600px) ? '344px !important' : 'auto'", width: '100%' }}
    >
      <Card className="w-full bg-[#0DB189] pl-2">
        <CardActions className="flex flex-row items-center justify-between">
          <div className="mx-0.5 flex items-center justify-center">
            <MdCheckCircle size={24} className="mr-1 text-[#FFFFFF]" />
            <Typography variant="body2" className="text-[12px] font-normal leading-[18px] text-[#FFFFFF]">
              {props.message}
            </Typography>
          </div>
          <div className="flex items-center">
            {props.buttonName && (
              <Button
                href={props.buttonHref}
                className="mr-2 rounded-[32px] border border-solid border-[#FFFFFF] px-[14px] py-[10px] text-[12px] font-bold normal-case leading-3 text-[#FFFFFF]"
              >
                {props.buttonName}
              </Button>
            )}

            <div className="ml-0.5 flex items-center  justify-center border-l border-solid border-[#FFFFFF]/20">
              <IconButton size="small" className="p-4" onClick={handleDismiss}>
                <MdClose size={16} color="#FFFFFF" />
              </IconButton>
            </div>
          </div>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

SuccessWithLink.displayName = 'SuccessWithLink';

interface CancelProcessProps extends CustomContentProps {
  buttonName?: string;
  onButtonClick?: () => void;
}

/**
 * Snackbarのカスタムコンテンツ
 * 表示：Defaultレイアウト
 * @param props notistackのCustomContentPropsをextend
 * @param buttonName ボタンのテキスト(任意)
 * @param onButtonClick  void ボタンのクリックイベント(任意)
 */
export const CancelProcess = forwardRef<HTMLDivElement, CancelProcessProps>(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent
      ref={ref}
      style={{ minWidth: "@media (min-width:600px) ? '344px !important' : 'auto'", width: '100%' }}
    >
      <Card className="w-full bg-[#4E454A] pl-2">
        <CardActions className="flex flex-row items-center justify-between">
          <div className="mx-0.5 flex items-center justify-center">
            <Typography variant="body2" className="text-[12px] font-normal leading-[18px] text-[#FFFFFF]">
              {props.message}
            </Typography>
          </div>

          <div className="flex items-center">
            <div className="ml-0.5 flex items-center  justify-center border-l border-solid border-[#FFFFFF]/20">
              <IconButton size="small" className="p-4" onClick={handleDismiss}>
                <MdClose size={16} color="#FFFFFF" />
              </IconButton>
            </div>
          </div>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

CancelProcess.displayName = 'CancelProcess';

/**
 * Snackbarのカスタムコンテンツ
 * 表示：Errorレイアウト
 * @param props notistackのCustomContentPropsをextend
 */
export const ErrorCustom = forwardRef<HTMLDivElement, CancelProcessProps>(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent
      ref={ref}
      style={{ minWidth: "@media (min-width:600px) ? '344px !important' : 'auto'", width: '100%' }}
    >
      <Card className="w-full bg-[#E53338] pl-2">
        <CardActions className="flex flex-row items-center justify-between">
          <div className="mx-0.5 flex items-center justify-center">
            <IoCloseCircle size={24} className="mr-1 text-[#FFFFFF]" />
            <Typography variant="body2" className="text-[12px] font-normal leading-[18px] text-[#FFFFFF]">
              {props.message}
            </Typography>
          </div>
          <div className="flex items-center">
            <div className="ml-0.5 flex items-center  justify-center border-l border-solid border-[#FFFFFF]/20">
              <IconButton size="small" className="p-4" onClick={handleDismiss}>
                <MdClose size={16} color="#FFFFFF" />
              </IconButton>
            </div>
          </div>
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

ErrorCustom.displayName = 'ErrorCustom';
